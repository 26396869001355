import { useCallback, useState } from "react";
import API, { NODE_API } from "../lib/api/api";
import usePopup from "./usePopup";
import useLanguage from "./useLanguage";
import { handleCatchPopup, removeUrlQueries } from "../lib/tools/helpers";
import { useUserSession } from "./useUserSession";
import { useSearchParams } from "next/navigation";
import assert from "@lib/tools/assert";
import { getDeviceInfo } from "@lib/tools/device";

export const useExternalAuth = () => {
  const setMessage = usePopup();
  const L = useLanguage(["common", "auth", "forms"]) as any;

  const { signInToken: login } = useUserSession();

  const query = useSearchParams();
  const loginType = query.get("login-type");
  const openid = query.get("openid.ns");
  const [stateExternalLogin, setStateExternalLogin] = useState({
    isLoading: !!openid || !!loginType,
    type: loginType ?? undefined,
  });

  const steam = useCallback(async () => {
    const device = await getDeviceInfo();

    API.post("steam/initiate", { device })
      .then((res) => {
        window.location.replace(res.data.url);
      })
      .catch(handleCatchPopup(setMessage));
  }, [setMessage]);

  const metaMaskLogin = useCallback(async () => {
    setStateExternalLogin({ isLoading: true, type: "metamask" });
    const localizedMessage = L("meta_mask_message");

    try {
      const provider = window.ethereum;
      assert(provider, "MetaMask provider is not installed", () => {
        setMessage({ code: "responses.metamask_not_installed", type: 0 });
      });

      assert(
        provider.isMetaMask,
        "This feature is only available for MetaMask users.",
        () => {
          setMessage({ code: "responses.only_meta_mask", type: 0 });
        }
      );

      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      const device = await getDeviceInfo();

      const res1 = await API.post("metamask/initiate", {
        address: accounts[0],
        stage: 1,
        device,
      });

      assert(res1.data.error, JSON.stringify(res1.data.error), () => {
        setMessage({ code: "responses." + res1.data.error, type: 0 });
        return true;
      });

      assert(res1.data.success);

      const message = `${localizedMessage} ${res1.data.hash}`;

      const signature = await provider.request({
        method: "personal_sign",
        params: [message, accounts[0]],
      });

      const res2 = await API.post("metamask/initiate", {
        signature,
        localized_message: localizedMessage,
        address: accounts[0],
        stage: 2,
        device,
      });

      assert(res2.data.tokens);

      await login(res2.data.tokens);
      setMessage({ code: "responses.successful", type: 1 });
      // no need to reset state because this closes the modal
      removeUrlQueries();
      return;
    } catch (err) {
      handleCatchPopup(setMessage)(err);
    }

    setStateExternalLogin({ isLoading: false, type: "" });
  }, [L, login, setMessage]);

  const googleLogin = useCallback(() => {
    window.location.href = `${NODE_API.getUri()}/v1/auth/google`;
  }, []);

  const loginSteam = useCallback(
    (query: URLSearchParams) => {
      const queryString = Array.from(query.entries())
        .map(([key, value]) => {
          if (key === "modal" || key === "tab") return;
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&")
        .trim()
        .substring(1);

      return API.get(`steam/initiate?${queryString}`).then((res) => {
        if (!res?.data?.tokens) throw res.data;
        return login(res.data.tokens);
      });
    },
    [login]
  );

  return {
    steam,
    metaMaskLogin,
    googleLogin,
    loginSteam,
    stateExternalLogin,
    setStateExternalLogin,
  };
};
