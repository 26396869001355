export const GoogleLogo = ({
  fill = "#FFFFFF",
  ...props
}) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill={fill} {...props} data-sentry-element="svg" data-sentry-component="GoogleLogo" data-sentry-source-file="GoogleLogo.jsx">
    <path fill={fill} d="M 16.003906 14.0625 L 16.003906 18.265625 L 21.992188 18.265625 C 21.210938 20.8125 19.082031 22.636719 16.003906 22.636719 C 12.339844 22.636719 9.367188 19.664063 9.367188 16 C 9.367188 12.335938 12.335938 9.363281 16.003906 9.363281 C 17.652344 9.363281 19.15625 9.96875 20.316406 10.964844 L 23.410156 7.867188 C 21.457031 6.085938 18.855469 5 16.003906 5 C 9.925781 5 5 9.925781 5 16 C 5 22.074219 9.925781 27 16.003906 27 C 25.238281 27 27.277344 18.363281 26.371094 14.078125 Z" data-sentry-element="path" data-sentry-source-file="GoogleLogo.jsx"></path>
  </svg>;
export const GoogleLogoModal = ({
  ...props
}) => <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22" fill="none" {...props} data-sentry-element="svg" data-sentry-component="GoogleLogoModal" data-sentry-source-file="GoogleLogo.jsx">
    <g clipPath="url(#clip0_21416_43634)" data-sentry-element="g" data-sentry-source-file="GoogleLogo.jsx">
      <path d="M5.38684 13.1028L4.68487 15.7234L2.11917 15.7776C1.3524 14.3555 0.91748 12.7283 0.91748 10.9992C0.91748 9.32711 1.32412 7.75033 2.04492 6.36194H2.04547L4.32966 6.78071L5.33028 9.05119C5.12085 9.66175 5.00671 10.3172 5.00671 10.9992C5.00678 11.7393 5.14086 12.4485 5.38684 13.1028Z" fill="#FBBB00" data-sentry-element="path" data-sentry-source-file="GoogleLogo.jsx" />
      <path d="M20.9076 9.11584C21.0234 9.72581 21.0838 10.3557 21.0838 10.9995C21.0838 11.7214 21.0079 12.4256 20.8633 13.1049C20.3725 15.4163 19.0899 17.4346 17.3131 18.8629L17.3126 18.8623L14.4355 18.7155L14.0283 16.1736C15.2073 15.4822 16.1287 14.4002 16.614 13.1049H11.2222V9.11584H20.9076Z" fill="#518EF8" data-sentry-element="path" data-sentry-source-file="GoogleLogo.jsx" />
      <path d="M17.3123 18.8613L17.3129 18.8618C15.5849 20.2508 13.3898 21.0818 11.0003 21.0818C7.16032 21.0818 3.82175 18.9355 2.11865 15.777L5.38632 13.1022C6.23785 15.3748 8.43015 16.9926 11.0003 16.9926C12.105 16.9926 13.14 16.6939 14.0281 16.1726L17.3123 18.8613Z" fill="#28B446" data-sentry-element="path" data-sentry-source-file="GoogleLogo.jsx" />
      <path d="M17.4375 3.238L14.1709 5.91229C13.2518 5.33777 12.1653 5.00589 11.0013 5.00589C8.37298 5.00589 6.13968 6.69788 5.3308 9.05199L2.04596 6.36273H2.04541C3.72358 3.1272 7.10425 0.916626 11.0013 0.916626C13.4479 0.916626 15.6912 1.78813 17.4375 3.238Z" fill="#F14336" data-sentry-element="path" data-sentry-source-file="GoogleLogo.jsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="GoogleLogo.jsx">
      <clipPath id="clip0_21416_43634" data-sentry-element="clipPath" data-sentry-source-file="GoogleLogo.jsx">
        <rect width={20.1667} height={20.1667} fill="white" transform="translate(0.916504 0.916626)" data-sentry-element="rect" data-sentry-source-file="GoogleLogo.jsx" />
      </clipPath>
    </defs>
  </svg>;