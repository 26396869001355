export default function MetaMaskIcon(props) {
  return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="MetaMaskIcon" data-sentry-source-file="MetaMaskIcon.jsx">
      <path d="M18.9273 2.45142L12.0566 7.55437L13.3272 4.5437L18.9273 2.45142Z" fill="#E2761B" stroke="#E2761B" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M3.06587 2.45142L9.88131 7.6027L8.6729 4.54369L3.06587 2.45142ZM16.4551 14.28L14.6252 17.0836L18.5404 18.1608L19.666 14.3422L16.4551 14.28ZM2.34082 14.3422L3.45946 18.1608L7.37472 17.0836L5.54484 14.28L2.34082 14.3422Z" fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M7.15352 9.54291L6.0625 11.1933L9.95013 11.3659L9.81203 7.18823L7.15352 9.54291ZM14.839 9.54291L12.146 7.13989L12.0562 11.3659L15.9369 11.1933L14.839 9.54291ZM7.37449 17.0834L9.70845 15.944L7.69213 14.3696L7.37449 17.0834ZM12.2841 15.944L14.625 17.0834L14.3004 14.3696L12.2841 15.944Z" fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M14.6255 17.0835L12.2846 15.9441L12.471 17.4701L12.4503 18.1123L14.6255 17.0835ZM7.375 17.0835L9.55014 18.1123L9.53633 17.4701L9.70896 15.9441L7.375 17.0835Z" fill="#D7C1B3" stroke="#D7C1B3" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M9.58399 13.3617L7.63672 12.7885L9.01086 12.1602L9.58399 13.3617ZM12.4082 13.3617L12.9814 12.1602L14.3624 12.7885L12.4082 13.3617Z" fill="#233447" stroke="#233447" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M7.3748 17.0835L7.70625 14.28L5.54492 14.3421L7.3748 17.0835ZM14.2938 14.28L14.6253 17.0835L16.4552 14.3421L14.2938 14.28ZM15.9373 11.1934L12.0565 11.366L12.4156 13.3616L12.9887 12.1601L14.3698 12.7885L15.9373 11.1934ZM7.6372 12.7885L9.01824 12.1601L9.58447 13.3616L9.95045 11.366L6.06281 11.1934L7.6372 12.7885Z" fill="#CD6116" stroke="#CD6116" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M6.0625 11.1934L7.69213 14.3698L7.63689 12.7885L6.0625 11.1934ZM14.3695 12.7885L14.3004 14.3698L15.9369 11.1934L14.3695 12.7885ZM9.95013 11.366L9.58416 13.3616L10.0399 15.7163L10.1435 12.6158L9.95013 11.366ZM12.0562 11.366L11.8698 12.6089L11.9526 15.7163L12.4153 13.3616L12.0562 11.366Z" fill="#E4751F" stroke="#E4751F" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M12.4151 13.3615L11.9525 15.7161L12.2839 15.944L14.3002 14.3696L14.3693 12.7883L12.4151 13.3615ZM7.63672 12.7883L7.69196 14.3696L9.70828 15.944L10.0397 15.7161L9.58399 13.3615L7.63672 12.7883Z" fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M12.4503 18.1124L12.471 17.4702L12.2984 17.3183H9.69515L9.53633 17.4702L9.55014 18.1124L7.375 17.0835L8.13457 17.705L9.67444 18.7753H12.3191L13.8659 17.705L14.6255 17.0835L12.4503 18.1124Z" fill="#C0AD9E" stroke="#C0AD9E" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M12.2844 15.9442L11.953 15.7163H10.0402L9.70876 15.9442L9.53613 17.4702L9.69495 17.3183H12.2982L12.4708 17.4702L12.2844 15.9442Z" fill="#161616" stroke="#161616" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M19.2176 7.88582L19.8046 5.06849L18.9276 2.45142L12.2848 7.38174L14.8397 9.54307L18.4512 10.5996L19.2522 9.66736L18.9069 9.41877L19.4593 8.91469L19.0312 8.58324L19.5836 8.16202L19.2176 7.88582ZM2.19629 5.06849L2.78323 7.88582L2.41035 8.16202L2.96277 8.58324L2.54155 8.91469L3.09397 9.41877L2.74871 9.66736L3.54281 10.5996L7.15423 9.54307L9.70916 7.38174L3.06635 2.45142L2.19629 5.06849Z" fill="#763D16" stroke="#763D16" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
      <path d="M18.4507 10.5996L14.8392 9.54307L15.9372 11.1934L14.3006 14.3698L16.4551 14.3422H19.666L18.4507 10.5996ZM7.15375 9.54307L3.54233 10.5996L2.34082 14.3422H5.54484L7.69236 14.3698L6.06273 11.1934L7.15375 9.54307ZM12.0565 11.366L12.2843 7.38174L13.3339 4.5437H8.6729L9.70868 7.38174L9.95036 11.366L10.0332 12.6228L10.0401 15.7163H11.9529L11.9667 12.6228L12.0565 11.366Z" fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MetaMaskIcon.jsx" />
    </svg>;
}